import './style.css'
import logo from './images/logo.png'

type TMenuDelGiornoProps = {
    proposta: Array<{
        nome: string
        prezzo: number
        opzione: Array<{
            testo: string
        }>
    }>
}

const MenuDelGiorno = (props: TMenuDelGiornoProps) => {
    let price = new Intl.NumberFormat('it', {
        style: 'currency',
        currency: 'EUR'
    })

    return (
        <main className='menu-del-giorno'>
            <div className='main-inner'>
                <div className="main-container">
                    <header>
                        <div className="logo">
                            <img src={logo} />
                        </div>
                        <div className="header-content">
                            <div className="divider"></div>
                            <div>
                                <h2>proposta del</h2>
                                <br />
                                <h1>giorno</h1>
                            </div>
                            <div className="divider"></div>
                        </div>
                    </header>

                    <div className="content-container">
                        {
                            props?.proposta?.map(proposta => {
                                return (
                                    <section key={proposta.nome}>
                                        <header>
                                            <h4>{proposta.nome}</h4>
                                            <span>€</span>
                                            <span>{price.format(proposta.prezzo)}</span>
                                        </header>
                                        <div>
                                            {
                                                proposta?.opzione?.map(opzione => {
                                                    return (
                                                        <p key={opzione.testo}>
                                                            {opzione.testo}
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </section>
                                )
                            })
                        }
                    </div>

                    <span className="disclaimer">
                        Per allergie ed entolleranze i signori clienti sono invitati a rivolgersi al personale di sala
                    </span>
                </div>
            </div>
        </main>
    )
}

export default MenuDelGiorno