type ErrorType = {
    message: string
}
const Error=({message}:ErrorType)=>{
    return(
        <div>
            <strong>{`Warning: `}</strong>{`${message}`}
        </div>
    )
  }
  export default Error;