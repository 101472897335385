import moment from 'moment'
import './style.css'
import CenterImage from './images/center_image.png'
import TemplateImage from './images/score_temlpate.png'
import LightningImage from './images/lightning.png'
import Logo from './images/logo.png'

type TImage = {
    data: {
        attributes: {
            url: string
        }
    }
}

type TEvento = {
    starts_at: string
    name_team_1: string
    name_team_2: string
    logo_team_1: TImage
    logo_team_2: TImage
    background_image: TImage
}

type TEventoDelGiornSportProps = {
    evento: TEvento
}

const EventoDelGiornoSport = (props: TEventoDelGiornSportProps) => {
    const {
        starts_at,
        name_team_1,
        name_team_2,
        logo_team_1,
        logo_team_2,
        background_image
    } = props?.evento

    return (
        <main className='evento-giorno-sport' style={{
            backgroundImage: `url("${background_image?.data?.attributes?.url}")`
        }}>
            <div className="container">
                <h1 className="title">
					<span>il {moment(starts_at).format("DD/MM")}</span>
                    <br />
                    <span>alle {moment(starts_at).format("hh:mm")}</span>
                </h1>
                <div className="content">
                    <div>
                        <img src={logo_team_1?.data?.attributes?.url} />
                    </div>
                    <div>
                        <img src={CenterImage} />
                    </div>
                    <div>
                        <img src={logo_team_2?.data?.attributes?.url} />
                    </div>
                </div>
                <div className="score">
                    <div className="score-container">
                        <div>
                            <span>{name_team_1}</span>
                        </div>
                        <div>
                            <span>{name_team_2}</span>
                        </div>
                    </div>
                    <img className="template" src={TemplateImage} />
                    <img className="lightning" src={LightningImage} />
                </div>
            </div>
            <div className="logo">
                <img src={Logo} />
            </div>
        </main>
    )
}

export default EventoDelGiornoSport