import './style.css'
import logo from './images/logo.png'
import Markdown from 'react-markdown'

type TMenuPranzoProps = {
    menu: {
        piatto: Array<{
            titolo: string
            tratteggio: boolean
            piatto: Array<{
                titolo: string
                prezzo: number
            }>
        }>
        banner_content: string
    }
}

const MenuPranzo = (props: TMenuPranzoProps) => {
    let price = new Intl.NumberFormat('it', {
        style: 'currency',
        currency: 'EUR'
    })

    return (
        <main className='menu-pranzo'>
            <div className="main-inner">
                <div className="main-container">
                    <header>
                        <div className="logo">
                            <img src={logo} />
                        </div>
                    </header>

                    <div className="content-container">
                        {
                            props?.menu?.piatto?.slice(0, props?.menu?.piatto?.length - 1)?.map(portata => {
                                return (
                                    <section key={portata?.titolo} className={`${portata?.tratteggio ? "dotted" : "normal"}`}>
                                        <h4 className="title">{portata?.titolo}</h4>
                                        <div className="menu-item-container">
                                            {
                                                portata?.piatto?.map(piatto => {
                                                    return (
                                                        <div className="menu-item" key={piatto.titolo}>
                                                            <Markdown className="menu-item-title">{piatto.titolo}</Markdown>
                                                            <span className='shrink-0'>{price.format(piatto.prezzo)}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </section>
                                )
                            })
                        }
                        <section>
                            <div className="banner">
                                <div>
                                    <span dangerouslySetInnerHTML={{ __html: props?.menu?.banner_content }}></span>
                                </div>
                            </div>
                            {
                                props?.menu?.piatto?.slice(props?.menu?.piatto?.length - 1, props?.menu?.piatto?.length)?.map(portata => {
                                    return (
                                        <section key={portata?.titolo}>
                                            <div className="menu-item-container">
                                                {
                                                    portata?.piatto?.map(piatto => {
                                                        return (
                                                            <div className="menu-item" key={piatto.titolo}>
																<Markdown className="menu-item-title">{piatto.titolo}</Markdown>
                                                                <span className='shrink-0'>{price.format(piatto.prezzo)}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </section>
                                    )
                                })
                            }
                            <span className="disclaimer">
                                Per allergie ed entolleranze i signori clienti sono invitati a rivolgersi al personale di sala
                            </span>
                        </section>
                    </div>

                </div>
            </div>
        </main>
    )
}

export default MenuPranzo