
import "./style.css"
import moment from "moment"
import 'moment/locale/it'
import { DATE_FORMAT_STRAPI } from "../../utils/consts";
import { TSingleImage } from "../../types/TSingleImage";

type TRissuntoSettimana = {
    data_limite: string;
    giorno: TRissuntoSettimanaGiorno[];
}
type TRissuntoSettimanaGiorno = {
    giorno: "Lunedi" | "Martedi" | "Mercoledi" | "Giovedi" | "Venerdi" | "Sabato" | "Domenica";
    evento: TRissuntoSettimanaGiornoEvento[];
    banner: TSingleImage
}
type TRissuntoSettimanaGiornoEvento = {
    descrizione: string;
    info: string;
    range_ora: string;
    titolo: string;
}
const RissuntoSettimana = (props: TRissuntoSettimana) => {
    return (
        <main className="riassunto-settimana">
            <div className="title">
                <h2>FINO AL {moment(props.data_limite).format("D MMMM")}</h2>
            </div>

            {
                props?.giorno?.map(giorno => {
                    return (
                        <div key={giorno.giorno} className="event">
                            <h3 className="event-day">{giorno.giorno}</h3>
                            <div className="event-content-container">
                                {
                                    giorno.evento?.map(evento => {
                                        return (
                                            <div key={evento.titolo} className="event-content">
                                                <div className="event-info">
                                                    <p className="event-hour">{evento.range_ora}</p>
                                                    <h3 className="event-title">{evento.titolo}</h3>
                                                    {
                                                        !!evento.descrizione ?
                                                            <p className="event-description">
                                                                {evento.descrizione}
                                                            </p> : null
                                                    }
                                                </div>
                                                {/* <h4 className="event-offer"></h4> */}
                                                {
                                                    !!evento.info ?
                                                        <span className="event-disclaimer">
                                                            {evento.info}
                                                        </span> : null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="event-banner">
                                <img src={giorno.banner.data.attributes.url} alt="banner"/>
                            </div>
                        </div>
                    )
                })
            }
        </main>
    )
}

export default RissuntoSettimana;
