import moment from "moment";
import { TPalinsestoContenuti } from "../types/TPalinsestoContenuti";
import { TStrapiResponse } from "../types/TStrapiResponse";
import clientGraphQl from "./interceptorGraphql";
import axios from "axios";

enum ContentType {
	DEFAULT,
	EXCEPTION,
}

const getPalinsestoContenutiForDs = async (
	idDs: number,
): Promise<TStrapiResponse<TPalinsestoContenuti>> => {
	return getContenutiForDs({ idDs, today: true }, ContentType.DEFAULT);
};
const getEccezionePalinsestoContenutiForDs = async (
	idDs: number,
): Promise<TStrapiResponse<TPalinsestoContenuti>> => {
	return getContenutiForDs({ idDs, today: true }, ContentType.EXCEPTION);
};

const getPalinsestoContenutiWithId = async (
	id: number,
): Promise<TStrapiResponse<TPalinsestoContenuti>> => {
	return getContenutiForDs(
		{ palinsestoContenutiId: id, today: false, preview: true },
		ContentType.DEFAULT,
	);
};
const getEccezionePalinsestoContenutiWithId = async (
	id: number,
): Promise<TStrapiResponse<TPalinsestoContenuti>> => {
	return getContenutiForDs(
		{ palinsestoContenutiId: id, today: false, preview: true },
		ContentType.EXCEPTION,
	);
};

interface IFilters {
	idDs?: number;
	palinsestoContenutiId?: number;
	today?: boolean;
	preview?: boolean;
}
const getContenutiForDs = async (
	filters: IFilters,
	type: ContentType,
): Promise<TStrapiResponse<TPalinsestoContenuti>> => {
	const day = moment().format("dddd");
	let _filters: string[] = [];
	let _filtersString = "";
	if (filters.today) {
		_filters = _filters.concat(`day: {eq: "${day}"}`);
	}
	if (filters.idDs) {
		_filters = _filters.concat(
			`dispositivo: { id: {eq: ${filters.idDs}} }`,
		);
	}
	if (filters.palinsestoContenutiId) {
		_filters = _filters.concat(
			`id: {eq: ${filters.palinsestoContenutiId}}`,
		);
	}
	_filtersString = _filters.join(",");
	return await (
		await clientGraphQl(
			`
      query {
        ${
			type === ContentType.DEFAULT
				? `scheduledContents`
				: `exceptionScheduleContents`
		}(
          pagination: { pageSize: 10 },
          ${filters.preview ? `publicationState: PREVIEW,` : ``}
          filters: { ${_filtersString} }) {
          data {
            id
            attributes {
              name
              day
              starts_at
              ends_at
              start_date
              end_date
              dispositivo {
                ... on DeviceEntityResponse {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
              templates {
                ... on ComponentTeplateScheduleTimer {
                  id
                  duration
                  template {
                    data {
                      id
                      attributes {
                        name
                        template {
                          __typename
                          ... on ComponentTemplatesVideo {
                            video {
                               ...on UploadFileEntityResponse {
                                  data {
                                    attributes {
                                      url 
                                    }
                                  }
                               }
                            }
                          }
                          ... on ComponentTemplatesImmagine {
                            immagine {
                               ...on UploadFileEntityResponse {
                                  data {
                                    attributes {
                                      url
                                      formats 
                                    }
                                  }
                               }
                            }
                          }
                          ... on ComponentTemplatesMenuCena{
                            menu{
                              titolo
                              sottotitolo
                              piatto{
                                titolo
                                descrizione
                                prezzo
                              }
                              info
                            }
                          }
                          ... on ComponentTemplatesRiassuntoSettimana{
                            data_limite
                            giorno{
                              evento{
                                range_ora
                                titolo
                                descrizione
                                info
                              }
                              giorno
                              banner{
                                ...on UploadFileEntityResponse {
                                  data {
                                    attributes {
                                      url
                                      formats 
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesScommessa{
                            titolo
                            single_scommessa{
                              Partita
                              quota
                              risultato
                            }
                            data
                            importo_di_vincita
							importo_da_giocare
							main_color
							bg_color
                            logo{
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesPalinsestoEventoSettimanale{
                            orario_inizio
                            orario_fine
                            giorno_settimana
                            backdrop
                            immagine_sfondo {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            immagine_nome_evento {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            immagine_badge_primo_piano {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            immagine_badge_secondo_piano {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesSportMatch{
                            starts_at
                            name_team_1
                            name_team_2
                            logo_team_1 {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            logo_team_2 {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            background_image {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesEventoSpeciale {
                            temporalizzazione
                            titolo
							background {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesSportGenerico{
                            titolo
                            sottotitolo
                            nome_squadra_1
                            nome_squadra_2
                            ora
                            immagine_bg {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            palla {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesSportPromo{
                            titolo
                            descrizione {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            prezzo
                            immagine_bg {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            immagine_sinistra {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            immagine_destra {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesMenuProposte {
                            proposta{
                              nome
                              prezzo
                              opzione{
                                testo
                              }
                            }
                          }
                          ... on ComponentTemplatesMenuPranzo {
                            banner_content
                            piatto{
                              titolo
                              tratteggio
                              piatto{
                                titolo
                                prezzo
                              }
                            }
                          }
                          ... on ComponentTemplatesMenuBevande {
                            top_titolo
                            titolo
                            vino{
                              Titolo
                              Prezzo_calice
                              Prezzo_bottiglia
                            }
                            lista_bevande{
                              Titolo
                              Bevanda{
                                Nome
                                Formato
                                Prezzo
                              }
                            }
                            bevanda_banner{
                              titolo
                              bevanda{
                                Nome
                                Formato
                                Prezzo
                              }
                            }
                          }
                          ... on ComponentTemplatesEventiGiornalieri {
                            logo{
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            data
                            proiezione{
                              ora
                              titolo
                            }
							main_color
							bg_color
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      `,
		)
	).data?.data?.[
		type === ContentType.DEFAULT
			? "scheduledContents"
			: "exceptionScheduleContents"
	];

	/**
 *   ... on ComponentTemplatesMenuPranzo {
        banner_content
        piatto {
          titolo
          tratteggio
          piatto {
            titolo
            prezzo
          }
        }
      }
      ... on ComponentTemplatesSportMatch {
        starts_at
        name_team_1
        name_team_2
      }
      ... on ComponentTemplatesRiassuntoSettimana {
        data_limite
        giorno {
          evento {
            range_ora
            titolo
            descrizione
            info
          }
          giorno
        }
      }
      ... on ComponentTemplatesSportGenerico {
        titolo
        sottotitolo
        nome_squadra_1
        nome_squadra_2
        ora
      }
   */
};

const getContenutoWithId = async (
	id: number,
): Promise<TStrapiResponse<TPalinsestoContenuti>> => {
	return await (
		await clientGraphQl(
			`
		query {
    template(id: ${id}){
        data {
            id
            attributes{
                name
                template {
                          __typename
                          ... on ComponentTemplatesVideo {
                            video {
                               ...on UploadFileEntityResponse {
                                  data {
                                    attributes {
                                      url 
                                    }
                                  }
                               }
                            }
                          }
                          ... on ComponentTemplatesImmagine {
                            immagine {
                               ...on UploadFileEntityResponse {
                                  data {
                                    attributes {
                                      url
                                      formats 
                                    }
                                  }
                               }
                            }
                          }
                          ... on ComponentTemplatesMenuCena{
                            menu{
                              titolo
                              sottotitolo
                              piatto{
                                titolo
                                descrizione
                                prezzo
                              }
                              info
                            }
                          }
                          ... on ComponentTemplatesRiassuntoSettimana{
                            data_limite
                            giorno{
                              evento{
                                range_ora
                                titolo
                                descrizione
                                info
                              }
                              giorno
                              banner{
                                ...on UploadFileEntityResponse {
                                  data {
                                    attributes {
                                      url
                                      formats 
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesScommessa{
                            titolo
                            single_scommessa{
                              Partita
                              quota
                              risultato
                            }
                            data
                            importo_di_vincita
							importo_da_giocare
							main_color
							bg_color
                            logo{
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesPalinsestoEventoSettimanale{
                            orario_inizio
                            orario_fine
                            giorno_settimana
                            backdrop
                            immagine_sfondo {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            immagine_nome_evento {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            immagine_badge_primo_piano {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            immagine_badge_secondo_piano {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesSportMatch{
                            starts_at
                            name_team_1
                            name_team_2
                            logo_team_1 {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            logo_team_2 {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                            background_image {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesEventoSpeciale {
                            temporalizzazione
                            titolo
							background {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesSportGenerico{
                            titolo
                            sottotitolo
                            nome_squadra_1
                            nome_squadra_2
                            ora
                            immagine_bg {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            palla {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesSportPromo{
                            titolo
                            descrizione {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            prezzo
                            immagine_bg {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            immagine_sinistra {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            immagine_destra {
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                          }
                          ... on ComponentTemplatesMenuProposte {
                            proposta{
                              nome
                              prezzo
                              opzione{
                                testo
                              }
                            }
                          }
                          ... on ComponentTemplatesMenuPranzo {
                            banner_content
                            piatto{
                              titolo
                              tratteggio
                              piatto{
                                titolo
                                prezzo
                              }
                            }
                          }
                          ... on ComponentTemplatesMenuBevande {
                            top_titolo
                            titolo
                            vino{
                              Titolo
                              Prezzo_calice
                              Prezzo_bottiglia
                            }
                            lista_bevande{
                              Titolo
                              Bevanda{
                                Nome
                                Formato
                                Prezzo
                              }
                            }
                            bevanda_banner{
                              titolo
                              bevanda{
                                Nome
                                Formato
                                Prezzo
                              }
                            }
                          }
                          ... on ComponentTemplatesEventiGiornalieri {
                            logo{
                              ...on UploadFileEntityResponse {
                                data {
                                  attributes {
                                    url
                                    formats 
                                  }
                                }
                              }
                            }
                            data
                            proiezione{
                              ora
                              titolo
                            }
							main_color
							bg_color
                          }
                        }
            }
        }
    }
}
		`,
		)
	).data?.data;
};

const getCurrentVersionAppFrontend = async (): Promise<number> => {
	try {
		const res = await axios.get(
			`https://${process.env.REACT_APP_AWS_BUCKET_NAME_SETTINGS}.s3.${process.env.REACT_APP_AWS_BUCKET_REGION_SETTINGS}.amazonaws.com/app.json`,
		);
		return res?.data?.version?.fe;
	} catch (error) {
		return 0;
	}
};

export {
	getCurrentVersionAppFrontend,
	getPalinsestoContenutiForDs,
	getEccezionePalinsestoContenutiForDs,
	getPalinsestoContenutiWithId,
	getEccezionePalinsestoContenutiWithId,
	getContenutoWithId,
};
