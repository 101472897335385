import { TSingleImage } from '../../types/TSingleImage'
import './style.css'

type TEvento = {
	temporalizzazione: string
	titolo: string
	background: TSingleImage
}

type TEventoDelGiornoGenericoProps = {
	evento: TEvento
}

const EventoDelGiornoGenerico = (props: TEventoDelGiornoGenericoProps) => {
	return (
		<main className='evento-del-giorno-generico' style={{
			backgroundImage: props?.evento?.background?.data?.attributes?.url ? `url("${props?.evento?.background?.data?.attributes?.url}")` : 'url("./images/background.png")'
		}}>
			<div className="container">
				<h3>{props?.evento?.temporalizzazione}</h3>
				<h1>
					{props?.evento?.titolo}
				</h1>
			</div>
		</main>
	)
}

export default EventoDelGiornoGenerico