import './style.css'
import { TSingleImage } from '../../types/TSingleImage'
import { formatHour } from '../../utils/utils'

type EventoSettimanle = {
    orario_inizio: string
    orario_fine: string
    giorno_settimana: string
    backdrop: boolean
    immagine_sfondo: TSingleImage
    immagine_nome_evento: TSingleImage
    immagine_badge_primo_piano: TSingleImage
    immagine_badge_secondo_piano: TSingleImage
}

type TEventoSettimanaleProps = {
    evento: EventoSettimanle
}

const EventoSettimanale = (props: TEventoSettimanaleProps) => {
    const {
        orario_inizio,
        orario_fine,
        giorno_settimana,
        backdrop,
        immagine_sfondo,
        immagine_nome_evento,
        immagine_badge_primo_piano,
        immagine_badge_secondo_piano
    } = props?.evento

    return (
        <main className="evento-settimanale" style={{
            backgroundImage: `url("${immagine_sfondo?.data?.attributes?.url}")`
        }}>
            {
                backdrop ? <div className="backdrop"></div> : null
            }
            <div id="hour-info">
                <h2>OGNI {giorno_settimana}</h2>
                <h2 className="hour-info-hour">{formatHour(orario_inizio)} - {formatHour(orario_fine)}</h2>
            </div>
            <div>
                <img src={immagine_nome_evento?.data?.attributes?.url} alt="title" />
            </div>
            <div className="banner">
                <img className="banner-img" src={immagine_badge_primo_piano?.data?.attributes?.url} alt="banner" />
                <img className="banner-offer" src={immagine_badge_secondo_piano?.data?.attributes?.url} alt="offer" />
            </div>
        </main>
    )
}

export default EventoSettimanale