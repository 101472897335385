import axios from 'axios';
// Aggiungi un interceptor per le richieste
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // Gestisci errori di richiesta
    return Promise.reject(error);
  }
);

// Aggiungi un interceptor per le risposte
axios.interceptors.response.use(
  function (response) {
    // Puoi personalizzare la gestione delle risposte qui
    // Ad esempio, gestire gli errori globali
    return response;
  },
  function (error) {
    // Gestisci errori di risposta
    return Promise.reject(error);
  }
);

const fetchGraphQlData = (query:any, variables?: any) => axios({
  method: 'post',
  url: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
  headers: { 
    'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN, 
    'Content-Type': 'application/json'
  },
  data : JSON.stringify({query, variables})
}) 


export default fetchGraphQlData;





