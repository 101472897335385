import moment from 'moment'
import './style.css'
import { TSingleImage } from '../../types/TSingleImage';

type TSingleScommessa = {
	Partita: string
	quota: number
	risultato: string
}

type TScommessa = {
	scommessa: {
		titolo: string
		single_scommessa: Array<TSingleScommessa>
		data: Date
		importo_di_vincita: number
		importo_da_giocare: number
		logo: TSingleImage
		main_color: string
		bg_color: string
	}
}

const Scommessa = (props: TScommessa) => {
	const { titolo, single_scommessa, data, importo_di_vincita, importo_da_giocare, main_color, bg_color, logo } = props?.scommessa

	console.log(importo_da_giocare)

	let price = new Intl.NumberFormat('it', {
		style: 'currency',
		currency: 'EUR'
	})

	return (
		<main className="scommessa" style={{ "--primary": main_color, "--secondary": bg_color } as React.CSSProperties}>
			<header>
				<nav>
					<div className="container">
						<div className="header-logo">
							<img src={logo?.data?.attributes?.url} alt="logo" />
							<h1>{titolo}</h1>
						</div>
						<div>
							<span className="header-date">{moment(data).format("DD MMMM")}</span>
						</div>
					</div>
				</nav>
			</header>
			<div className="content">
				<table>
					<thead>
						<tr>
							<td>
								<span>PARTITA</span>
							</td>
							<td>
								<span>RIS.</span>
							</td>
							<td>
								<span>QUOTA</span>
							</td>
						</tr>
					</thead>
					<tbody>
						{
							single_scommessa?.map(scommessa => {
								return (
									<tr key={scommessa.Partita}>
										<td>
											<span>{scommessa.Partita}</span>
										</td>
										<td>
											<span>{scommessa.risultato}</span>
										</td>
										<td>
											<span>{scommessa.quota}</span>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
				<div className="bet">
					<div>
						<label htmlFor="bet-amount">IMP. DA GIOCARE</label>
						<input id="bet-amount" type="text" readOnly value={price.format(importo_da_giocare)} />
					</div>
					<div>
						<label htmlFor="win-amount">VINCITA</label>
						<input id="win-amount" type="text" readOnly value={price.format(importo_di_vincita)} />
					</div>
				</div>
			</div>
		</main>
	)
}

export default Scommessa