import "./style.css"
import logo from "./images/logo.png"
import stars from "./images/stars.png"
import vs_template from "./images/vs_template.png"
import bg_date from "./images/bg_date.png"
import moment from "moment"
import { TIME_FORMAT } from "../../utils/consts"
import { TSingleImage } from "../../types/TSingleImage"

type TEventoSportivoGenerico = {
    evento: {
        titolo: string;
        sottotitolo: string;
        nome_squadra_1: string;
        nome_squadra_2: string;
        ora: string;
        immagine_bg: TSingleImage;
        palla: TSingleImage;
    }
}
const EventoSportivoGenerico = (props: TEventoSportivoGenerico) => {
    const { titolo, sottotitolo, nome_squadra_1, nome_squadra_2, ora, immagine_bg, palla } = props?.evento

    return (
        <div id="html-evento-sportivo-generico" style={{
            backgroundImage: `url("${immagine_bg.data.attributes.url}")`
        }}>
            <img className="ball-bg" src={palla.data.attributes.url}></img>
            <img className="logo" src={logo} />
            <main>
                <header>
                    <h1>{titolo}</h1>
                    <h4>{sottotitolo}</h4>
                    <img className="stars" src={stars} />
                </header>
                <div className="vs-container">
                    <div className="vs">
                        <img src={vs_template} />
                        <div className="vs-content">
                            <div>
                                <h2>
                                    {nome_squadra_1}
                                </h2>
                            </div>
                            <div>
                                <h2>
                                    {nome_squadra_2}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="date">
                    <div className="date-content">
                        <span className="label">ore</span>
                        <span className="hour">{moment(ora, TIME_FORMAT).hours()}</span>
                    </div>
                    <img src={bg_date} />
                </div>
            </main>
        </div>
    )
}

export default EventoSportivoGenerico;