import { TSingleImage } from "../../types/TSingleImage";
import logo from './images/logo.png';
import './style.css'

type TEventoSportGenerico2Props = {
	evento: {
		titolo: string;
		descrizione: TSingleImage;
		prezzo: string;
		immagine_sinistra: TSingleImage;
		immagine_destra: TSingleImage;
		immagine_bg: TSingleImage;
	}
}

const EventoSportGenerico2 = (props: TEventoSportGenerico2Props) => {
	const { titolo, descrizione, prezzo, immagine_sinistra, immagine_destra, immagine_bg } = props?.evento

	return (
		<main className="evento-sport-generico-2">
			<div className="left-col" style={{
				backgroundImage: `url("${immagine_sinistra.data.attributes.url}")`
			}}>

			</div>
			<div className="central-col" style={{
				backgroundImage: `url("${immagine_bg.data.attributes.url}")`
			}}>
				<h2 className="title" dangerouslySetInnerHTML={{ __html: titolo }}>
				</h2>
				<img className="banner" src={descrizione.data.attributes.url} alt="1 birra media + 1 pizza o 1 hamburger" />
				{
					prezzo &&
					<div className="offer">
						<span>A SOLI</span>
						<h2>{prezzo}</h2>
					</div>
				}

				<img className="logo" src={logo} alt="hippo logo" />
			</div>
			<div className="right-col" style={{
				backgroundImage: `url("${immagine_destra.data.attributes.url}")`
			}}>

			</div>
		</main>
	)
}

export default EventoSportGenerico2