import './style.css';
type TVideo = {
    videoSource: string;
}
const Video = ({ videoSource }:TVideo) => {
  return (
    <div className="fullscreen-video-container">
      <video className="fullscreen-video" autoPlay controls={false} muted>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;