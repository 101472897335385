import { lazy, useEffect, useRef, useState } from "react";
import './App.css';
import { getContenutoWithId, getCurrentVersionAppFrontend, getEccezionePalinsestoContenutiForDs, getEccezionePalinsestoContenutiWithId, getPalinsestoContenutiForDs, getPalinsestoContenutiWithId } from "./utils/network";
import { TPalinsestoContenuti } from "./types/TPalinsestoContenuti";
import Error from "./components/Error";
import moment from "moment";
import 'moment/dist/locale/it';
import { getDayNameFromDayNumber } from "./utils/utils";
//Importati i templates component subito
import Video from './templates/Video'
import Immagine from './templates/Immagine'
import MenuCena from "./templates/Il menu cena";
import Scommessa from "./templates/Scommessa del giorno";
import EventoSettimanale from "./templates/Evento settimanale";
import EventoDelGiornoSport from "./templates/Evento del giorno";
import EventoDelGiornoGenerico from "./templates/Evento del giorno generico";
import EventoSportGenerico2 from "./templates/Evento sport generico 2";
import MenuDelGiorno from "./templates/Menu del giorno";
import MenuPranzo from "./templates/Il menu pranzo";
import MenuBevande from "./templates/Il menu bevande";
import EventoGiornaliero from "./templates/Evento giornaliero";
import EventoSportGenerico from "./templates/Evento sport generico";
import RiassuntoSettimana from "./templates/Riassunto settimana";

const Loader = lazy(() => import('./components/Loader'));

function App() {

  const queryParameters = new URLSearchParams(window.location.search)
  // Lista dei palinsesti scaricati per il DS corrente
  const [palinsestiContenuti, setPalinsestiContenuti] = useState<{ id: number; attributes: TPalinsestoContenuti; }[]>([])
  const refPalinsestiContenuti = useRef(palinsestiContenuti);

  const [eccezionePalinsestiContenuti, setEccezionePalinsestiContenuti] = useState<{ id: number; attributes: TPalinsestoContenuti; }[]>([])
  const refEccezionePalinsestiContenuti = useRef(eccezionePalinsestiContenuti);

  // Palinsesto corrente
  const [palinsestiContenutiCorrenti, setPalinsestiContenutiCorrenti] = useState<{ id: number; attributes: TPalinsestoContenuti; }[]>();
  const refPalinsestiContenutiCorrenti = useRef(palinsestiContenutiCorrenti);
  // Singolo contenuto del palinsesto corrente
  const [palinsestoContenutoCorrente, setPalinsestoContenutoCorrente] = useState<{ id: number; duration: number; template: any }>();

  const currentItemIndexRef = useRef(-1);
  const refTimerContent = useRef(0);
  const refVersioneApp = useRef<number | undefined>(undefined);
  // Digital Signage: viene impostato direttamente nell'URL
  const DIGITAL_SIGNAGE_ID = queryParameters?.get("ds");
  const IS_MANUAL_MODE: boolean = queryParameters?.get("mode") === "manual";
  const ID_PALINSESTO_CONTENUTI: number = Number(queryParameters?.get("palinsestocontenuti"));
  const ID_CONTENUTO: number = Number(queryParameters?.get("contenuto"));

  const SECONDS_FOR_CHECK_REFRSH_CONTENT = 20;
  const SECONDS_FOR_DOWNLOAD_ALL_CONTENTS = 60;
  const SECONDS_FOR_CHECK_CODE = 90;


  async function downloadAllPalinsestoForCurrentDs() {
    try {
      const response = await getPalinsestoContenutiForDs(DIGITAL_SIGNAGE_ID ? Number(DIGITAL_SIGNAGE_ID) : -1)
      setPalinsestiContenuti(response.data);

      const responseException = await getEccezionePalinsestoContenutiForDs(DIGITAL_SIGNAGE_ID ? Number(DIGITAL_SIGNAGE_ID) : -1)
      setEccezionePalinsestiContenuti(responseException.data);
    } catch (error) {
      //TODO: Handle exception while refreshing data
    }
  }

  function setupCurrentTemplateToShow() {
    const currentDay = getDayNameFromDayNumber(Number(moment().format("d"))).toLowerCase();
    const currentTime = moment();
    const currentOnlyDate = moment(moment().format("YYYY-MM-DD"));

    const getCurrentScheduleContent = (list: any[]) => {
      let templates: any[] = [];

      if (!!ID_PALINSESTO_CONTENUTI) {
        JSON.parse(JSON.stringify(list))?.forEach((it: any) => templates = templates.concat(it?.attributes?.templates))
        return templates;
      };

      JSON.parse(JSON.stringify(list)).filter((it: any) => {
        const isBetweenTime = currentTime.isBetween(moment(it?.attributes?.starts_at, 'HH:mm'), moment(it?.attributes?.ends_at, 'HH:mm'));
        const isDayOfTheWeek = String(it?.attributes?.day).toLocaleLowerCase() === currentDay;
        const isInTheDaysRange = (!it?.attributes?.start_date || currentOnlyDate.isSameOrAfter(moment(it?.attributes?.start_date))) && (!it?.attributes?.end_date || currentOnlyDate.isSameOrBefore(moment(it?.attributes?.end_date)))
        console.log({ isBetweenTime, isDayOfTheWeek, isInTheDaysRange, it })
        return isDayOfTheWeek && isBetweenTime && isInTheDaysRange
      })?.forEach((it: any) => templates = templates.concat(it?.attributes?.templates))
      return templates;
    }

    const exceptionContenutiToShow = getCurrentScheduleContent(refEccezionePalinsestiContenuti.current);
    const contenutiToShow = exceptionContenutiToShow?.length ? exceptionContenutiToShow : getCurrentScheduleContent(refPalinsestiContenuti.current);

    if (!refPalinsestiContenutiCorrenti.current?.length || JSON.stringify(contenutiToShow) != JSON.stringify(refPalinsestiContenutiCorrenti.current)) {
      // TODO: Verifica Reset del timer perché si riparte dalla prima slide
      console.log("[AGGIORNAMENTO CONTENUTI VISIBILI]", contenutiToShow)
      setPalinsestiContenutiCorrenti(contenutiToShow)
    } else {
      console.log("... SKIP, STESSI CONTENUTI VISIBILI",)
    }
  }

  /* Verifica la slide corretta da mostrare */
  function setupSingleItemOfCurrentTemplateToShow() {
    let sumDurations = 0;
    refPalinsestiContenutiCorrenti.current?.forEach((singleItem: any, indexSingleItem: number) => {
      sumDurations += singleItem?.duration;
      const sumTillPrevItem = sumDurations - singleItem?.duration
      if (currentItemIndexRef.current != indexSingleItem && refTimerContent.current < sumDurations && refTimerContent.current >= sumTillPrevItem) {
        console.log(`[SET CONTENT AT INDEX ${indexSingleItem} WITH DURATION ${singleItem?.duration}s]`)
        currentItemIndexRef.current = indexSingleItem;
        // console.log(">>>>", singleItem)
        setPalinsestoContenutoCorrente(singleItem);
      }
      //@ts-ignore
      if (refTimerContent.current >= refPalinsestiContenutiCorrenti?.current?.reduce((accumulator, currentValue) => accumulator + currentValue?.duration, 0)) {
        console.log("[RESET TIMER - RESTART FROM INITIAL SLIDE]")
        refTimerContent.current = 0;
        currentItemIndexRef.current = 0;
        //@ts-ignore
        setPalinsestoContenutoCorrente(refPalinsestiContenutiCorrenti.current[0]!);
      }
    });

  }

  async function verifyAppVersion() {
    try {
      const newVersion = await getCurrentVersionAppFrontend()
      if (refVersioneApp?.current === undefined) {
        refVersioneApp.current = newVersion;
      } else if (refVersioneApp?.current !== newVersion && newVersion > refVersioneApp?.current) {
        console.log(`[REFRESH WEB PAGE - APP VERSION CHANGED from ${refVersioneApp?.current} to ${newVersion} ]`)
        setTimeout(() => { window.location.reload() }, 1000)
      }
    } catch (error) {
      // continue
    }
  }

  /* Allo startup vengono scaricati tutti i contenuti */
  useEffect(() => {
    console.log(`Manual Mode: ${IS_MANUAL_MODE}`)
    if (!ID_PALINSESTO_CONTENUTI) {
      downloadAllPalinsestoForCurrentDs();
    }
  }, [])

  /* Quando viene aggiornato il palinsesto contenuti viene verificato il template da visualizzare */
  useEffect(() => {
    refPalinsestiContenuti.current = palinsestiContenuti;
    setupCurrentTemplateToShow();
  }, [palinsestiContenuti])

  /* Quando viene aggiornato il palinsesto eccezione contenuti viene verificato il template da visualizzare */
  useEffect(() => {
    refEccezionePalinsestiContenuti.current = eccezionePalinsestiContenuti;
    setupCurrentTemplateToShow();
  }, [eccezionePalinsestiContenuti])

  /* Quando viene aggiornato il palinsesto contenuti corrente verifico se devo switchare le slides */
  useEffect(() => {
    refPalinsestiContenutiCorrenti.current = palinsestiContenutiCorrenti;
  }, [palinsestiContenutiCorrenti])

  /* Ogni $SECONDS_FOR_CHECK_REFRSH_CONTENT secondi viene verificato (ed eventualmente aggiornato) il template che deve essere visibili attualmente */
  useEffect(() => {
    const interval = window.setInterval(() => {
      console.log(`...VERIFY TO SHOW NEW CONTENTS...`);
      setupCurrentTemplateToShow()
    }, SECONDS_FOR_CHECK_REFRSH_CONTENT * 1000);
    return () => window.clearInterval(interval);
  }, []);

  /* Ogni secondo verifico se alternare il contenuto corrente */
  useEffect(() => {
    if (!IS_MANUAL_MODE) {
      const interval = window.setInterval(() => {
        refTimerContent.current += 1;
        // console.log(`...VERIFY TO SHOW SINGLE CONTENT...(${refTimerContent.current}s)`);
        setupSingleItemOfCurrentTemplateToShow()
      }, 1000);
      return () => window.clearInterval(interval);
    }
  }, []);


  /* Ogni $SECONDS_FOR_DOWNLOAD_ALL_CONTENTS secondi vengono riscaricati tutti i contenuti */
  useEffect(() => {
    if (!IS_MANUAL_MODE) {
      const interval = window.setInterval(() => {
        console.log(`[DOWNLOAD ALL CONTENTS]`);
        downloadAllPalinsestoForCurrentDs();
      }, SECONDS_FOR_DOWNLOAD_ALL_CONTENTS * 1000);
      return () => window.clearInterval(interval);
    }
  }, []);


  /* Ogni $SECONDS_FOR_CHECK_CODE secondi viene scaricata la versione dell'app Frontend per capire se fare refresh della pagina web */
  useEffect(() => {
    if (!IS_MANUAL_MODE) {
      const interval = window.setInterval(() => {
        console.log(`...VERIFY APP VERSION...`);
        verifyAppVersion();
      }, SECONDS_FOR_CHECK_CODE * 1000);
      return () => window.clearInterval(interval);
    }
  }, []);

  /* Get Single Palinsesto contenuti */
  useEffect(() => {
    async function exec() {
      if (!!ID_PALINSESTO_CONTENUTI) {
        console.log(`Get contents for Palinsesto contenuti ID: ${ID_PALINSESTO_CONTENUTI}`)
        const response = await getPalinsestoContenutiWithId(ID_PALINSESTO_CONTENUTI)
        setPalinsestiContenuti(response.data);

        const responseException = await getEccezionePalinsestoContenutiWithId(ID_PALINSESTO_CONTENUTI)
        setEccezionePalinsestiContenuti(responseException.data);
      }
    }
    exec();
  }, [ID_PALINSESTO_CONTENUTI])

  /* Manual mode */
  useEffect(() => {
    async function init() {
      if (IS_MANUAL_MODE) {
        currentItemIndexRef.current = 0;
        if (ID_CONTENUTO) {
          const singleContenuto = await getContenutoWithId(ID_CONTENUTO);
          setPalinsestoContenutoCorrente(JSON.parse(JSON.stringify(singleContenuto)));
          setPalinsestiContenutiCorrenti([{
            id: 1,
            attributes: singleContenuto as any
          }])
        }
        else if (!!refPalinsestiContenutiCorrenti!.current?.length) {
          setPalinsestoContenutoCorrente(JSON.parse(JSON.stringify(refPalinsestiContenutiCorrenti!.current![0] as any)));
        }
      }
    }

    init()
  }, [IS_MANUAL_MODE, refPalinsestiContenutiCorrenti.current])

  function goPrev() {
    const prev_index: number = currentItemIndexRef.current - 1
    currentItemIndexRef.current = prev_index < 0 ? refPalinsestiContenutiCorrenti.current!.length - 1 : prev_index;
    setPalinsestoContenutoCorrente(refPalinsestiContenutiCorrenti!.current![currentItemIndexRef.current] as any);
  }
  function goNext() {
    const next_index: number = currentItemIndexRef.current + 1
    currentItemIndexRef.current = next_index < refPalinsestiContenutiCorrenti.current!.length ? next_index : 0;
    setPalinsestoContenutoCorrente(refPalinsestiContenutiCorrenti!.current![currentItemIndexRef.current] as any);
  }

  const getCurrentComponent = () => {
    let component;
    const componentData = palinsestoContenutoCorrente?.template?.data?.attributes?.template?.[0];
    if (componentData?.__typename)
      console.log(`Component Visible: ${componentData?.__typename}`)
    if (componentData?.__typename === "ComponentTemplatesImmagine") {
      component = <Immagine key={componentData?.immagine?.data?.attributes?.url} imgSource={componentData?.immagine?.data?.attributes?.url} />
    } else if (componentData?.__typename === "ComponentTemplatesVideo") {
      component = <Video key={componentData?.video?.data?.attributes?.url} videoSource={componentData?.video?.data?.attributes?.url} />
    } else if (componentData?.__typename === "ComponentTemplatesMenuCena") {
      component = <MenuCena menus={componentData?.menu} />
    } else if (componentData?.__typename === "ComponentTemplatesRiassuntoSettimana") {
      component = <RiassuntoSettimana data_limite={componentData?.data_limite} giorno={componentData?.giorno} />
    } else if (componentData?.__typename === "ComponentTemplatesScommessa") {
      component = <Scommessa scommessa={componentData} />
    } else if (componentData?.__typename === "ComponentTemplatesPalinsestoEventoSettimanale") {
      component = <EventoSettimanale evento={componentData} />
    } else if (componentData?.__typename === "ComponentTemplatesSportMatch") {
      component = <EventoDelGiornoSport evento={componentData} />
    } else if (componentData?.__typename === "ComponentTemplatesEventoSpeciale") {
      component = <EventoDelGiornoGenerico evento={componentData} />
    } else if (componentData?.__typename === "ComponentTemplatesSportGenerico") {
      console.log("COMPONENT DATA _-----------_", componentData)
      component = <EventoSportGenerico evento={componentData} />
    } else if (componentData?.__typename === "ComponentTemplatesSportPromo") {
      component = <EventoSportGenerico2 evento={componentData} />
    } else if (componentData?.__typename === "ComponentTemplatesMenuProposte") {
      component = <MenuDelGiorno proposta={componentData?.proposta} />
    } else if (componentData?.__typename === "ComponentTemplatesMenuPranzo") {
      component = <MenuPranzo menu={componentData} />
    } else if (componentData?.__typename === "ComponentTemplatesMenuBevande") {
      component = <MenuBevande menu={componentData} />
    } else if (componentData?.__typename === "ComponentTemplatesEventiGiornalieri") {
      component = <EventoGiornaliero evento={componentData} />
    }

    return component || <Loader />;
  }
  return (
    <div className="App">
      {IS_MANUAL_MODE && !ID_CONTENUTO ? <div style={{ background: "white", border: "1px solid red", position: "fixed", top: 0, padding: "2px 10px", borderRadius: 5 }}>
        {`${currentItemIndexRef.current + 1}/${refPalinsestiContenutiCorrenti.current?.length || 0}`}
      </div> : null}
      {IS_MANUAL_MODE && !ID_CONTENUTO ? <button onClick={goPrev} style={{ position: "fixed", left: 20, cursor: "pointer", zIndex: 999 }}>
        <img style={{ width: 50, height: 50 }} src="/img/back_arrow.png" />
      </button> : null}
      {
        !!DIGITAL_SIGNAGE_ID || !!ID_PALINSESTO_CONTENUTI || !!ID_CONTENUTO ? getCurrentComponent() : <Error message={`Digital Signage non impostato`} />
      }
      {IS_MANUAL_MODE && !ID_CONTENUTO ? <button onClick={goNext} style={{ position: "fixed", right: 20, transform: "rotate(180deg)", cursor: "pointer", zIndex: 999 }}>
        <img style={{ width: 50, height: 50 }} src="/img/back_arrow.png" />
      </button> : null}
    </div>
  );
}

export default App;
