import './style.css'
import logo from './images/logo.png'

type TBevanda = {
	Nome: string
	Formato: string
	Prezzo: number
}

type TListingBevande = Array<{
	Titolo?: string
	Bevanda?: Array<TBevanda>
	titolo?: string
	bevanda?: Array<TBevanda>
}>

type TMenuBevandeProps = {
	menu: {
		top_titolo: string
		titolo: string
		vino: Array<{
			Titolo: string
			Prezzo_calice: number
			Prezzo_bottiglia: number
		}>
		lista_bevande: TListingBevande
		bevanda_banner: TListingBevande
	}
}

const MenuBevande = (props: TMenuBevandeProps) => {
	const { top_titolo, titolo, vino, lista_bevande, bevanda_banner } = props?.menu

	let price = new Intl.NumberFormat('it', {
		style: 'currency',
		currency: 'EUR'
	})

	return (
		<main className='menu-bevande'>
			<div className="main-inner">
				<div className="main-container">
					<header>
						<div className="logo">
							<img src={logo} />
						</div>
						<div className="header-content">
							<div className="divider"></div>
							<div>
								<h2>{top_titolo}</h2>
								<h1>{titolo}</h1>
							</div>
							<div className="divider"></div>
						</div>
					</header>

					<div className="content-container">
						<p>
							<span></span>
							<h3>Calice</h3>
							<h3>Bottiglia</h3>
						</p>
						{
							vino?.map(v => {
								return (
									<p>
										<h4>{v.Titolo}</h4>
										<span>{v.Prezzo_calice ? price.format(v.Prezzo_calice) : ""}</span>
										<span>{price.format(v.Prezzo_bottiglia)}</span>
									</p>
								)
							})
						}
					</div>

					<footer>
						{
							lista_bevande?.map(lista => {
								return (
									<div className="content">
										<h3>{lista.Titolo}</h3>
										{
											lista?.Bevanda?.map(b => {
												return (
													<div className="container">
														<h4>{b.Nome}</h4>
														<span>{b.Formato}</span>
														<span>{price.format(b.Prezzo)}</span>
													</div>
												)
											})
										}
									</div>
								)
							})
						}
						<div className="banner">
							<div className="banner-content">
								{
									bevanda_banner?.map(section => {
										return (
											<section className={section?.titolo?.includes("SPECIAL") ? "yellow" : ""}>
												<h3>{section.titolo}</h3>
												{
													section?.bevanda?.map(b => {
														return (
															<div className="container">
																<h4>{b.Nome}</h4>
																<span>{b.Formato}</span>
																<span>{price.format(b.Prezzo)}</span>
															</div>
														)
													})
												}
											</section>
										)
									})
								}
							</div>
						</div>
					</footer>
				</div>
			</div>
		</main>
	)
}

export default MenuBevande