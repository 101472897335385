export const getDayNameFromDayNumber = (dayNumber: number):string => {
    switch (dayNumber) {
        case 1:
            return "lunedi"
        case 2:
            return "martedi"
        case 3:
            return "mercoledi"
        case 4:
            return "giovedi"
        case 5:
            return "venerdi"
        case 6:
            return "sabato"
        case 7:
            return "domenica"
        default:
            return "";
    }
}

export function formatHour(hour: string): string {
    return hour.split(":").slice(0, 2).join(":")
}