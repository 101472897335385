import moment from 'moment'
import 'moment/locale/it'
import { TSingleImage } from '../../types/TSingleImage'
import './style.css'
import { formatHour } from '../../utils/utils'

type TEventoGiornalieroProps = {
	evento: {
		logo: TSingleImage
		data: string
		proiezione: Array<{
			ora: string
			titolo: string
		}>
		main_color: string
		bg_color: string
	}
}

const EventoGiornaliero = (props: TEventoGiornalieroProps) => {
	const { logo, data, proiezione, main_color, bg_color } = props?.evento

	return (
		<main className='evento-giornaliero' style={{ "--primary": main_color, "--secondary": bg_color } as React.CSSProperties}>
			<header>
				<nav>
					<div className="container">
						<div className="header-logo">
							<img src={logo.data.attributes.url} alt="logo" />
							<h1>sport in tv</h1>
						</div>
						<div>
							<span className="header-date">{moment(data).format("dddd DD MMMM")}</span>
						</div>
					</div>
				</nav>
			</header>
			<div className="content">
				<table>
					{
						proiezione?.map(p => {
							return (
								<tr key={p?.titolo}>
									<td>
										<span>{formatHour(p?.ora)}</span>
									</td>
									<td>
										<span>{p?.titolo}</span>
									</td>
								</tr>
							)
						})
					}
				</table>
			</div>
		</main>
	)
}

export default EventoGiornaliero