import './style.css'
import logo from './images/logo.png'

type TMenu = {
    titolo: string
    sottotitolo: string
    piatto: [{
        titolo: string
        descrizione: string
        prezzo: number
    }]
    info: string
}

const MenuCena = ({ menus }: { menus: Array<TMenu> }) => {
    let price = new Intl.NumberFormat('it', {
        style: 'currency',
        currency: 'EUR'
    })

    return (
        <main className='menu-cena'>
            <div className="main-container">
                <header className="header">
                    <div className="logo">
                        <img src={logo} />
                    </div>

                    <p className="title">dalle 19.00 alle 23.00 burger mania & pizza</p>
                </header>

                <div className="content">
                    {
                        menus?.map(menu => {
                            return (
                                <section key={menu.titolo}>
                                    <header>
                                        <div className="header-content">
                                            <div className="divider"></div>
                                            <div>
                                                <h1>{menu.titolo}</h1>
                                                <h2>{menu.sottotitolo}</h2>
                                            </div>
                                            <div className="divider"></div>
                                        </div>
                                    </header>
                                    <div className="content-container">
                                        {
                                            menu.piatto?.map(piatto => {
                                                return (
                                                    <section key={piatto.titolo}>
                                                        <header>
                                                            <h4>{piatto.titolo}</h4>
                                                            <div className="price">
                                                                <span>{price.format(piatto.prezzo)}</span>
                                                            </div>
                                                        </header>
                                                        <p>{piatto.descrizione}</p>
                                                    </section>
                                                )
                                            })
                                        }
                                    </div>
                                    <span className="disclaimer">
                                        {menu.info}
                                    </span>
                                </section>
                            )
                        })
                    }
                </div>
            </div>
        </main>
    )
}

export default MenuCena